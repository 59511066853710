body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {

  text-decoration: none;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sideNavBox img{width:2rem;max-width:100%;}
a{
    color:inherit;
}

.sideNavBox .MuiListItem-button{color: #414042;}
*{
    margin:0rem;padding:0rem;
}

.MenuBox  h3{
   
  font-size:1.3125rem;
  color:#212529;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  color: inherit;
}

.container {
  /* border: 1px solid black; */
  margin-top: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    /* font-size: 1.2rem; */
    color: #064e89;
    text-align: left;
  }
  .formControl {
    min-width: 200px;

    position: relative;
    display: flex;
    align-items: end;
    margin-bottom: 1rem;
  }

  .select {
    width: 100%;
    padding: 10px 30px 10px 10px;

    border-color: #064e89;
    border-width: 2px;
    border-radius: 4px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: white;
    font-size: 16px;
    font-weight: bold;
    color: #064e89;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #064e89;
  }

  .filter {
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-top: 2rem; */
    /* width: 100%; */
    font-size: 1rem;
  }
  .languageArticleFilter {
    display: flex;
    align-items: flex-end;
    .langSvg {
      font-size: 1.8rem;
    }
    svg {
      font-size: 1.5rem;
    }
    span {
      margin-left: 0.3rem;
    }
  }
  .cards {
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    gap: 16px;
    .paper {
      width: 210px;
      height: 150px;
      text-align: left;
      border-radius: 10px;
      h3 {
        padding: 10px;
        font-size: 1rem;
        height: 2rem;
      }
      p {
        margin-top: 0.5rem;
        padding: 10px;
        font-size: 1.5rem;
        font-weight: bold;
      }
      .otherCount {
        height: 5rem;
      }
      img {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: -1.5rem;
      }
    }
    .change-indicator {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      color: #6c757d;
      padding: 5px;
    }

    .change-indicator.positive {
      color: green;
    }

    .change-indicator.negative {
      color: red;
    }

    .arrow-icon {
      font-size: 1.25rem;
    }
  }
}
.trainGrContain {
  margin-top: 1.5rem;

  h2 {
    /* font-size: 1.2rem; */
    color: #064e89;
    text-align: left;
  }
  .card {
    padding: 10px;
    border-radius: 10px;

    p {
      text-align: end;
    }

    img {
      width: 40px;
      margin-top: -0.2rem;
    }

    .details {
      margin-top: 1rem;
      display: flex;
      position: relative;
      .count {
        width: 85%;
      }
      .eventName {
        text-align: left;
      }
      .progress {
        position: relative;
        height: "5px";
        .linearProgress {
        }
        /* display: inline-block; */
      }
      .progressBar {
        background-color: #2fea9b;
      }
      .progressGRBar {
        background-color: #056aff;
      }
      .progressArticleBar {
        background-color: #ce8d6a;
      }
      .progressCount {
        position: absolute;
        left: 94%;
        top: 10px;

        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.topTraining {
  margin-top: 1.5rem;

  h2 {
    /* font-size: 1.2rem; */
    color: #064e89;
    text-align: left;
  }
  .customTable {
    margin-top: 1rem;
  }
  td {
    padding: 20px;
  }
}

*{margin:0rem;padding:0rem;}

.customTable .MuiTableCell-sizeSmall{
    white-space: nowrap;
}
.customTable .MuiTableCell-head{
    color: #414042;
    font-weight: bold;
    font-size:0.9375rem;
}
.textField .MuiOutlinedInput-root{border-radius:0px;}
.buttonCancel .MuiButton-containedSecondary{
    background:#f26522;
}
.buttonCancel .MuiButton-containedSecondary:hover{
    background:#f26522;
}

.MuiAlert-message h2{
    color:#035eab;
}

.customSwitch .MuiSwitch-colorSecondary.Mui-checked{
    color:#3EAC3F;
}

.customSwitch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color:#3EAC3F;
}
.mce-container.mce-panel.mce-floatpanel.mce-window.mce-in {
	z-index: 150000 !important;
}
.TrainingMenu h3 {
  font-size: 1.3125rem;
  color: #212529;
}
.TrainingMenu {
  margin-top: 1.2rem;
}
.main {
  margin-top: 5rem;
  /* width: 95%;
  margin-left: 2rem; */
}
.TrainingMenu-paper-2 {
  margin-top: 2rem;
  /* margin-left: 5rem;
  margin-right: 5rem; */
  /* width: 80%; */
}

