*{margin:0rem;padding:0rem;}

.customTable .MuiTableCell-sizeSmall{
    white-space: nowrap;
}
.customTable .MuiTableCell-head{
    color: #414042;
    font-weight: bold;
    font-size:0.9375rem;
}
.textField .MuiOutlinedInput-root{border-radius:0px;}
.buttonCancel .MuiButton-containedSecondary{
    background:#f26522;
}
.buttonCancel .MuiButton-containedSecondary:hover{
    background:#f26522;
}

.MuiAlert-message h2{
    color:#035eab;
}

.customSwitch .MuiSwitch-colorSecondary.Mui-checked{
    color:#3EAC3F;
}

.customSwitch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
    background-color:#3EAC3F;
}