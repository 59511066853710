.TrainingMenu h3 {
  font-size: 1.3125rem;
  color: #212529;
}
.TrainingMenu {
  margin-top: 1.2rem;
}
.main {
  margin-top: 5rem;
  /* width: 95%;
  margin-left: 2rem; */
}
.TrainingMenu-paper-2 {
  margin-top: 2rem;
  /* margin-left: 5rem;
  margin-right: 5rem; */
  /* width: 80%; */
}
