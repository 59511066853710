a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  color: inherit;
}

.container {
  /* border: 1px solid black; */
  margin-top: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    /* font-size: 1.2rem; */
    color: #064e89;
    text-align: left;
  }
  .formControl {
    min-width: 200px;

    position: relative;
    display: flex;
    align-items: end;
    margin-bottom: 1rem;
  }

  .select {
    width: 100%;
    padding: 10px 30px 10px 10px;

    border-color: #064e89;
    border-width: 2px;
    border-radius: 4px;
    appearance: none;
    background-color: white;
    font-size: 16px;
    font-weight: bold;
    color: #064e89;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #064e89;
  }

  .filter {
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-top: 2rem; */
    /* width: 100%; */
    font-size: 1rem;
  }
  .languageArticleFilter {
    display: flex;
    align-items: flex-end;
    .langSvg {
      font-size: 1.8rem;
    }
    svg {
      font-size: 1.5rem;
    }
    span {
      margin-left: 0.3rem;
    }
  }
  .cards {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    .paper {
      width: 210px;
      height: 150px;
      text-align: left;
      border-radius: 10px;
      h3 {
        padding: 10px;
        font-size: 1rem;
        height: 2rem;
      }
      p {
        margin-top: 0.5rem;
        padding: 10px;
        font-size: 1.5rem;
        font-weight: bold;
      }
      .otherCount {
        height: 5rem;
      }
      img {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: -1.5rem;
      }
    }
    .change-indicator {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.875rem;
      color: #6c757d;
      padding: 5px;
    }

    .change-indicator.positive {
      color: green;
    }

    .change-indicator.negative {
      color: red;
    }

    .arrow-icon {
      font-size: 1.25rem;
    }
  }
}
.trainGrContain {
  margin-top: 1.5rem;

  h2 {
    /* font-size: 1.2rem; */
    color: #064e89;
    text-align: left;
  }
  .card {
    padding: 10px;
    border-radius: 10px;

    p {
      text-align: end;
    }

    img {
      width: 40px;
      margin-top: -0.2rem;
    }

    .details {
      margin-top: 1rem;
      display: flex;
      position: relative;
      .count {
        width: 85%;
      }
      .eventName {
        text-align: left;
      }
      .progress {
        position: relative;
        height: "5px";
        .linearProgress {
        }
        /* display: inline-block; */
      }
      .progressBar {
        background-color: #2fea9b;
      }
      .progressGRBar {
        background-color: #056aff;
      }
      .progressArticleBar {
        background-color: #ce8d6a;
      }
      .progressCount {
        position: absolute;
        left: 94%;
        top: 10px;

        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.topTraining {
  margin-top: 1.5rem;

  h2 {
    /* font-size: 1.2rem; */
    color: #064e89;
    text-align: left;
  }
  .customTable {
    margin-top: 1rem;
  }
  td {
    padding: 20px;
  }
}
